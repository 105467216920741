import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAccount } from 'core/hooks/useAccount';
import { AccountType } from '@multiversx/sdk-dapp/types';
import { useGetLoginInfo, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { TokensView, Denominate, useCoreContext, isMobile, useCoreDispatch } from 'core';
import { 
	ExtensionLoginButton,
	WebWalletLoginButton, 
	WalletConnectLoginContainer,
	LedgerLoginContainer
} from '@multiversx/sdk-dapp/UI';

enum ConnectionType {
	Ledger = 1,
	MaiarApp = 2,
	MaiarAppV2 = 3,
	Wallet = 4,
	None = 5
}

export const ConnectModal = ({
    show,
	onlyWithSigning,
    onHide,
}: {
    show: boolean;
	onlyWithSigning?: boolean;
    onHide: () => void;
}): React.ReactElement => {

	const dispatch = useCoreDispatch()
	const { address } = useGetAccountInfo()
	const { account } = useCoreContext()
	const { disconnect, loadAccount, isLoggedIn } = useAccount()

	const [type, setType] = React.useState(ConnectionType.None)

	const logout = () => {
		onHide()
		setType(ConnectionType.None)
		disconnect()
	}

	const fetchUser = () => {
		if (isLoggedIn()) {
			loadAccount()
		}

		dispatch({ type: 'setAddress', address: address })
		dispatch({ type: 'setIsLoggedIn', isLoggedIn: isLoggedIn() })
	}
	React.useEffect(fetchUser, [address])

	const defaultContent = () => {
		return (
			<React.Fragment>
				<p>Connect with your MultiversX Wallet.</p>
				<h4 className="mb-4">Please select your login method</h4>
				<div className="mb-4 d-flex flex-column">
					{!isMobile.any() &&
						<ExtensionLoginButton
							buttonClassName="extension-login"
							loginButtonText="Browser Extension"
						/>
					}
					<button 
						className="dapp-core-component__main__btn dapp-core-component__main__btn-primary dapp-core-component__main__px-4 dapp-core-component__main__m-1 dapp-core-component__main__mx-3"
						onClick={() => setType(ConnectionType.MaiarAppV2)}
					>
						<span className="dapp-core-component__loginButtonStyles__login-text">xPortal</span>
					</button>
					{!onlyWithSigning &&
						<WebWalletLoginButton
							buttonClassName="webwallet-login"
							loginButtonText="Web Wallet"
							callbackRoute={window.location.pathname}
						/>
					}
					{!onlyWithSigning && !isMobile.any() &&
						<button 
							className="dapp-core-component__main__btn dapp-core-component__main__btn-primary dapp-core-component__main__px-4 dapp-core-component__main__m-1 dapp-core-component__main__mx-3"
							onClick={() => setType(ConnectionType.Ledger)}
						>
							<span className="dapp-core-component__loginButtonStyles__login-text">Ledger</span>
						</button>
					}
				</div>
			</React.Fragment>
		)
	}

	const walletContent = (account: AccountType) => {
		return (
			<React.Fragment>
				<h4 className="mb-4 text-break-all">{account.address}</h4>
				<div className="d-flex flex-column align-items-center justify-content-center mb-3">
					<Denominate value={account.balance} token="EGLD" decimals={5} bold />
					<TokensView />
				</div>
				<button onClick={logout} className="disconnect-button center-button px-sm-spacer mx-1 mx-sm-3 mb-3">
					Disconnect
				</button>
			</React.Fragment>
		)
	}

	const chooseTemplate = () => {
		if (account) {
			return walletContent(account)
		}
		switch (type) {
			case ConnectionType.Ledger:
				return (
					<LedgerLoginContainer
						className='ledger-login-modal'
						wrapContentInsideModal={false}
						onClose={onHide}
					/>
				)
			case ConnectionType.MaiarApp:
				return ( 
					<WalletConnectLoginContainer
						loginButtonText="Login with xPortal"
						title='Legacy Maiar Login'
						className='wallect-connect-login-modal'
						lead='Scan the QR code using xPortal'
						wrapContentInsideModal={false}
						onClose={onHide}
						isWalletConnectV2={false}
					/>
				)
			case ConnectionType.MaiarAppV2:
				return ( 
					<WalletConnectLoginContainer
						loginButtonText="Login with xPortal"
						title='xPortal Login'
						className='wallect-connect-login-modal'
						lead='Scan the QR code using xPortal'
						wrapContentInsideModal={false}
						onClose={onHide}
						isWalletConnectV2={true}
					/>
				)
			default:
				return defaultContent()
		}
	}

	const hideModal = () => {
		setType(ConnectionType.None)
		onHide()
	}
	useEffect(() => {
		if (isLoggedIn()) hideModal()
	}, [address])
	
	return (
		<Modal
			show={show}
			onHide={hideModal}
			animation={false}
			size="lg"
			centered
		>
			<Modal.Header closeButton className="btn-close-white"></Modal.Header>
			<Modal.Body className="center">
				{ chooseTemplate() }
			</Modal.Body>
		</Modal>
	)
}
