import React, { useEffect } from 'react'
import { NftModel } from 'models/NftModel';
import { LineButton } from '../uielements/LineButton';
import { delay, DelegationTransactionType, nominateNumberToHex, nominateStringToHex, useAccount, useCoreContext, useTransactions } from 'core';
import { nftAttributesContract } from 'config';
import { Address, BinaryCodec, Field, FieldDefinition, StringType, StringValue, Struct, StructType, U32Type, U32Value } from '@multiversx/sdk-core/out';
import { contractViews } from 'contracts/ContractViews';
import { attributeConfig } from 'utils/attributes.config';

interface NFTAttributesRarity {
	[key: string]: string;
	rarity: string;
}

export const NFTView = ({
	index,
    esdt,
	collection,
	reload,
	upgradeable,
}: {
	index: number;
    esdt: NftModel;
	collection: {[key: string]: any};
	reload: () => void;
	upgradeable?: boolean;
}): React.ReactElement => {

	const { proxy } = useAccount()
	const { address } = useCoreContext()
	const { sessionId, sendTransaction } = useTransactions()
	
	const [points, setPoints] = React.useState(0)
	const [attributes, setAttributes] = React.useState({} as NFTAttributesRarity);
	const [usedPoints, setUsedPoints] = React.useState({} as { [key: string]: number })

	useEffect(() => { 
		generateAttributes() 
		loadPoints()
	}, [esdt]);

	const loadPoints = async () => {
		if (!esdt.isRegistered()) return
		await delay(index * 200)
		const points = await contractViews.getPoints(proxy(), parseInt(esdt.nonce))
			.catch(err => {
				console.log(err)
			})
		if (points) setPoints(points)
	}

	const generateAttributes = () => {
		var attributes: NFTAttributesRarity = {
			rarity: ''
		}
		var rarityScore = 0
		esdt.metadata.attributes.forEach((attribute) => {
			attributes[attribute.trait_type] = (collection[attribute.trait_type][attribute.value]['attributeFrequency'] * 100).toFixed(2)
			rarityScore += collection[attribute.trait_type][attribute.value]['attributeRarity']
		})
		attributes.rarity = (rarityScore * 7).toFixed(0)
		setAttributes(attributes)
	}

	const register = () => {
		const args = []
		args.push(nominateStringToHex(esdt.collection))
		args.push(nominateNumberToHex(esdt.nonce.toString()))
		args.push(nominateNumberToHex('1'))
		args.push(new Address(nftAttributesContract).hex())
		args.push(nominateStringToHex('registerNft'))

		const data = args.join('@')
		const txArguments = new DelegationTransactionType(address, '0', 'ESDTNFTTransfer', data)
		sendTransaction([txArguments], 'registerNft', 'Register NFT', () => delay(2000).then(reload))
	}

	const getStructure = (): StructType => {
        return new StructType('UpgradeParam', [
            new FieldDefinition('key', '', new StringType()),
            new FieldDefinition('points', '', new U32Type()),
        ]);
    }

	const upgrade = () => {
		const args = []
		args.push(nominateStringToHex(esdt.collection))
		args.push(nominateNumberToHex(esdt.nonce.toString()))
		args.push(nominateNumberToHex('1'))
		args.push(new Address(nftAttributesContract).hex())
		args.push(nominateStringToHex('upgradeNft'))

		const codec = new BinaryCodec()
		const vec: string[] = []
		Object.keys(usedPoints).forEach((key) => {
			const fixedKey = key.toLowerCase().replace('fire rate', 'fireRate')
			vec.push(codec.encodeNested(new Struct(getStructure(), [
				new Field(new StringValue(fixedKey), 'key'),
				new Field(new U32Value(usedPoints[key]), 'points'),
			])).toString('hex'))
		})
		args.push(vec.join(''))

		const data = args.join('@')
		const txArguments = new DelegationTransactionType(address, '0', 'ESDTNFTTransfer', data)
		sendTransaction([txArguments], 'upgradeNft', 'Upgrade NFT', () => {
			delay(2000).then(reload)
			setUsedPoints({})
		})
	}

	const totalUsedPoints = (): number => {
		let total = 0
		Object.keys(usedPoints).forEach(key => { total += usedPoints[key] })
		return total
	}

	const addPoint = (key: string) => {
		if (points == totalUsedPoints()) return
		const config = attributeConfig(key)
		if (totalAdded(key) + (usedPoints[key] ?? 0) * config.step > config.start + config.max) return

		setUsedPoints({
			...usedPoints,
			[key]: (usedPoints[key] ?? 0) + 1
		})
	}

	const removePoint = (key: string) => {
		setUsedPoints({
			...usedPoints,
			[key]: Math.max((usedPoints[key] ?? 0) - 1, 0)
		})
	}

	const totalAdded = (key: string): number => {
		const config = attributeConfig(key)
		if (!config) return 0
		return (usedPoints[key] ?? 0) * config.step
	}

	return (
		<>
		<div className="card d-flex flex-row mb-4">
			<div className="nft-details-side d-flex flex-column w-50">
				<img src={esdt.url} className="center round-corners float-image" alt="Krogan Spaceship" />
				<h5 className="mt-1 mb-0">Level {esdt.level}</h5>
				<h4 className="mb-2">{esdt.name}</h4>
				<p className="mb-1 footnote">Visual Rarity Score {attributes.rarity}</p>
				{upgradeable && 
				<>
					{esdt.isRegistered() ?
						<React.Fragment>
							<p><span className="colored">{points}</span> upgrade points</p>
							{totalUsedPoints() > 0 && 
								<>
									<p className='footnote'>Used <span className='colored footnote'>{totalUsedPoints()}</span> points</p>
									<LineButton className="mt-2" onClick={upgrade}>UPGRADE</LineButton>
								</>
							}
						</React.Fragment>
					:
						<LineButton className="my-2" small sessionId={sessionId} onClick={register}>REGISTER</LineButton>
					}
				</>
				}
			</div>
			<div className="nft-content d-flex flex-column w-50 text-left">
				<h5 className="mt-2 mb-1">Visual Attributes</h5>
				{esdt.metadata.attributes.map((attribute, i) => (
					<div key={i}>
						<p className="caption">
							{attribute.trait_type}: <span className="caption colored">{attribute.value}</span>
							&nbsp;(&nbsp;{attributes[attribute.trait_type]}%&nbsp;)
						</p>
					</div>
				)) }

				{esdt.isRegistered() &&
					<React.Fragment>
						<h5 className="mt-2 mb-1">Dynamic Attributes</h5>
						{esdt.dynamicAttributes.map((attribute, i) => (
							<div key={i}>
								<p className="caption mb-1">
									<span className="caption" style={{ display: 'inline-block', minWidth: 75 }}>
										{attribute.trait_type}:
									</span>
									{upgradeable && 
										<LineButton type="primary" className="xs" onClick={() => removePoint(attribute.trait_type)}>-</LineButton>
									}
									<span className={`caption center ${usedPoints[attribute.trait_type] ? 'correct' : ''}`} style={{ display: 'inline-block', minWidth: 20 }}>
										{parseInt(attribute.value) + totalAdded(attribute.trait_type)}
									</span>
									{upgradeable && 
										<LineButton type="primary" className="xs" onClick={() => addPoint(attribute.trait_type)}>+</LineButton>
									}
								</p>
							</div>
						)) }
					</React.Fragment>
				}
			</div>
		</div>
		</>
	);
};
