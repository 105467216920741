import React, { useState } from 'react';
import { getAccount } from '@multiversx/sdk-dapp/utils'
import { AccountType } from '@multiversx/sdk-dapp/types'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { logout } from '@multiversx/sdk-dapp/utils'
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { useCoreContext, useCoreDispatch } from 'core/context';
import { coreAccountService } from 'core/services/account.service';
import { network } from 'config';

export function useAccount() {
	const dispatch = useCoreDispatch()
	const { address } = useGetAccountInfo()
	const { kroTicker, followTokens } = useCoreContext()

	const [inProgressTx, setInProgressTx] = useState({} as { [key: string]: (success: boolean) => void })

	const refreshTokenList = () => {
		if (!isLoggedIn()) return
		console.log('[Account] Loading KRO balance')
		coreAccountService.fetchAccountTokens(address, kroTicker).then(values => {
			dispatch({ type: 'setKroToken', token: values.first() });
		})

		if (followTokens.length == 0) return
		coreAccountService.fetchAccountMultiTokens(address, followTokens)
			.then(values => {
				dispatch({ type: 'setGeneralTokens', tokens: values })
			})
	}

	const isLoggedIn = () => {
		return address != undefined && address != ''
	}

	const loadAccount = async () => {
		if (isLoggedIn()) {
			const account = await getAccount(address)
			if (account) {
				console.log(`Account loaded ${account.address}`)
				setLocalContext(account)
			}
		} else {
			setLocalContext(undefined)
		}
	}

	const disconnect = () => {
		logout()
		setLocalContext(undefined)
	}

	const setLocalContext = (account: AccountType | undefined) => {
		dispatch({ type: 'setAccount', account: account ? account : undefined })
		dispatch({ type: 'setAddress', address: account ? account.address : '...' })
		dispatch({ type: 'setIsLoggedIn', isLoggedIn: account != undefined })
	}

	const addTx = (key: string, func: (success: boolean) => void) => {
		setInProgressTx({
			...inProgressTx,
			[key]: func,
		})
	}

	const removeTx = (key: string) => {
		setInProgressTx(current => {
			const copy = {...current}
			delete copy[key]
			return copy
		})
	}

	const proxy = () => {
		return new ProxyNetworkProvider(network.apiAddress, { timeout: 6000 })
	}

	return {
		addTx,
		removeTx,
		inProgressTx,
		loadAccount,
		disconnect,
		refreshTokenList,
		proxy,
		isLoggedIn
	}
}