import { ESDTToken, Session } from "core/helpers";
import { AccountType } from '@multiversx/sdk-dapp/types';


const defaultLocalApiAddress = 'https://api.kroganswap.com';

export interface CoreStateType {
	api: string;
	kroTicker: string;
	kroToken?: ESDTToken;
	lkkroTokens: ESDTToken[];
	showLoginModal: boolean;
	account?: AccountType;
	address: string;
	isLoggedIn: boolean;

	// Subscribe to updates with followTokens, read generalTokens
	followTokens: string[];
	generalTokens: ESDTToken[];

	// Used in network header
	sessionKey?: string;
}

export const initialState = (api: string, kroTicker: string): CoreStateType => {
	
	return {
		api: api !== undefined ? api : defaultLocalApiAddress,
		kroTicker: kroTicker,
		lkkroTokens: [],
		showLoginModal: false,
		address: Session.getItem('user_address') ?? '',
		isLoggedIn: false,
		followTokens: [],
		generalTokens: [],
		sessionKey: Session.getItem('game_session'),
	};
};