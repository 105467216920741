import { DelegationContractType } from 'core';
import { BaseNetworkType } from '@multiversx/sdk-dapp/types';
import { ExchangeToken } from 'utils/types';

export const nftMinterContract = 'erd1qqqqqqqqqqqqqpgqdfm60dszgux8nmcj6yq08zq0fayhfw03xqtsj9lvyx'
export const egldWrapperContract: string = 'erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3'
export const nftAttributesContract = 'erd1qqqqqqqqqqqqqpgqwlhl4ztw7g38kdj53zvn94j93wchmktjxqtsz9avgu'

export const kroTicker = 'KRO-878dce';
export const lkkroTicker = 'LKKRO-6679cf';
export const kroganNFT = 'KROGAN-65489c';

export const kroganApi = ["localhost", "192.168.8.100"].includes(location.hostname)
	? 'http://localhost:1370'
	: ["dan-macbook-pro.local"].includes(location.hostname)
		? 'http://dan-macbook-pro.local:1370'
		: 'https://devnet-api.kroganswap.com';
export const gateway = 'https://devnet-gateway.multiversx.com';

export const network: BaseNetworkType = {
	id: 'devnet',
	chainId: 'D',
	name: 'Devnet',
	egldLabel: 'xEGLD',
	decimals: '18',
	digits: '2',
	gasPerDataByte: '1500',
	walletConnectDeepLink: 'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/',
	walletAddress: 'https://devnet-wallet.multiversx.com/dapp/init',
	apiAddress: 'https://devnet-api.multiversx.com',
	explorerAddress: 'http://devnet-explorer.multiversx.com',
	apiTimeout: '4000',
	walletConnectV2ProjectId: 'dd783f271f7935e443f9df1b1442ac70',
};

export const exchangeTokens: ExchangeToken[] = []

export const delegationContractData: DelegationContractType[] = [
	{
		name: 'ESDTNFTTransfer',
		gasLimit: 30000000,
	}, {
		name: 'ESDTTransfer',
		gasLimit: 30000000,
	}, {
		name: 'enterPrivateSale',
		gasLimit: 30000000,
	}, {
		name: 'enterSale',
		gasLimit: 30000000,
	}, {
		name: 'withdraw',
		gasLimit: 10000000,
	}, {
		name: 'ClaimDeveloperRewards',
		gasLimit: 10000000,
	}, {
		name: 'ESDTLocalBurn',
		gasLimit: 2000000,
	}, {
		name: 'setSpecialRole',
		gasLimit: 100000000,
	}, {
		name: 'createCategories',
		gasLimit: 10000000,
	}
];
