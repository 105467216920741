import { Session } from 'core/helpers';
import { CoreStateType } from './state';

export type CoreDispatchType = (action: CoreActionType) => void;

export type CoreActionType =
	{ type: 'setKroToken'; token: CoreStateType['kroToken'] }
	| { type: 'setLKKroTokens'; tokens: CoreStateType['lkkroTokens'] }
	| { type: 'setShowLoginModal'; showLoginModal: CoreStateType['showLoginModal'] }
	| { type: 'setAccount'; account: CoreStateType['account'] }
	| { type: 'setAddress'; address: CoreStateType['address'] }
	| { type: 'setIsLoggedIn'; isLoggedIn: CoreStateType['isLoggedIn'] }
	| { type: 'setGeneralTokens'; tokens: CoreStateType['generalTokens'] }
	| { type: 'setFollowTokens'; tokens: CoreStateType['followTokens'] }
	| { type: 'setSessionKey'; sessionKey: CoreStateType['sessionKey'] }

export function reducer(state: CoreStateType, action: CoreActionType): CoreStateType {
	switch (action.type) {

		case 'setKroToken': {
			const { token } = action;
			return {
				...state,
				kroToken: token,
			};
		}
		
		case 'setLKKroTokens': {
			const { tokens } = action;
			return {
				...state,
				lkkroTokens: tokens,
			};
		}
		
		case 'setGeneralTokens': {
			const { tokens } = action;
			return {
				...state,
				generalTokens: tokens,
			};
		}
		
		case 'setFollowTokens': {
			const { tokens } = action;
			return {
				...state,
				followTokens: tokens,
			};
		}

		case 'setShowLoginModal': {
			const { showLoginModal } = action;
			return {
				...state,
				showLoginModal,
			};
		}

		case 'setAccount': {
			const { account } = action;
			return {
				...state,
				account,
			};
		}

		case 'setAddress': {			
			const { address } = action;
			Session.setItem('user_address', address, 365*24*3600) // 1yr
			return {
				...state,
				address,
			};
		}

		case 'setIsLoggedIn': {
			const { isLoggedIn } = action;
			return {
				...state,
				isLoggedIn,
			};
		}

		case 'setSessionKey': {
			const { sessionKey } = action;
			Session.setItem('game_session', sessionKey, 365*24*3600) // 1yr
			return {
				...state,
				sessionKey,
			};
		}

		default: {
			throw new Error(`Unhandled action type: ${action}`);
		}
	}
}
