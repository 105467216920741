import React from 'react';
import classNames from 'classnames';
import { denominate } from './formatters';
import { TokenImage } from './PaymentView';

export interface DenominateType {
	value: string;
	className?: string;
	token?: string;
	denomination?: number;
	showLastNonZeroDecimal?: boolean;
	showErd?: boolean;
	decimals?: number;
	bold?: boolean;
	header?: boolean;
}

export const Denominate = ({
	value,
	className = '',
	token = 'EGLD',
	denomination = 18,
	showLastNonZeroDecimal = false,
	showErd = true,
	decimals,
	bold = false,
	header = true,
}: DenominateType) => {

	decimals = decimals ?? 3;

	const denominatedValue = denominate({
		input: value,
		denomination,
		decimals,
		showLastNonZeroDecimal,
	});

	const classes = classNames(className, {
		'text-strong': bold,
		'text-header': bold && header,
	})

	return (
		<span data-testid="denominateComponent" className={className}>
			<span className={classes}>{denominatedValue}</span>
			{showErd && <span className="symbol">&nbsp;{TokenImage(token)}</span>}
		</span>
	);
};