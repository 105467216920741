/* eslint-disable react-hooks/exhaustive-deps */
import 'utils/extensions'
import BigNumber from 'bignumber.js'
import React, { useEffect } from 'react'
import classNames from 'classnames'
import { contractViews } from 'contracts/ContractViews'
import { Col, Row } from 'react-bootstrap'
import FormRange from 'react-bootstrap/FormRange'
import { CategoryType } from 'utils/types'
import { kroganApi, kroTicker, nftMinterContract } from 'config';
import AdminView from 'views/components/Admin';
import { accountService } from 'services/account';
import { useQuery } from 'hooks/useQuery';
import { Address } from '@multiversx/sdk-core/out';
import { 
	DelegationTransactionType, denominate, 
	nominateNumberToHex, nominateStringToHex, Session, 
	useCoreDispatch, useTransactions, useAccount, useCoreContext
} from 'core';

import kro from 'images/KRO-df97ec.gif'

import level1 from 'images/1level.gif'
import level2 from 'images/2level.gif'
import level3 from 'images/3level.gif'
import level4 from 'images/4level.gif'
import level5 from 'images/5level.gif'
import level6 from 'images/6level.gif'
import level7 from 'images/7level.gif'
import level8 from 'images/8level.gif'
import level9 from 'images/9level.gif'
import level10 from 'images/10level.gif'
import { ConnectButtonContent } from 'core/components/ConnectButtonContent';
import { LineButton } from 'views/components/uielements/LineButton';
import { useHistory } from 'react-router-dom';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEvents'


const MintSection = ({
	alt,
}: {
	alt: boolean;
}): React.ReactElement => {

	const query = useQuery()
	const history = useHistory()

	const { proxy } = useAccount()
	const { address, isLoggedIn, kroToken } = useCoreContext()
	const coreDispatch = useCoreDispatch()
	const { sendTransaction } = useTransactions()
	const gaEventTracker = useAnalyticsEventTracker('Mint')
	
	const { getAllCategories } = contractViews
	const [amounts, setAmounts] = React.useState([1,1,1,1,1,1,1,1,1,1])
	const [categories, setCategories] = React.useState([] as CategoryType[])

	const [referralUrl, setReferralUrl] = React.useState('')
	const [referred, setReferred] = React.useState(Session.getItem('referred'))
	
	// const isSunday = (date: Date) => date.getDay() % 7 === 0
	const available = [1,1,1,1,1,1,1,1,1,1] 
	// const available = 
	// 	(isSunday(new Date()) 
	// 		|| address == 'erd1mhs8t9xhyq8d5uqfd3vptm77k45avksqe6ekk87rflehrxa9tjus0yg6fz'
	// 		|| address == 'erd18h9sx7jp3w7z59eeq3f2an6wtzmwjz39rzxnd2gf7x8m3f2mwe0s2gdgr5'
	// 	) ? [1,1,1,1,1,1,1,1,1,1] : [1,1,1,1,0,0,0,0,0,0]
	const limitPurchase = [4,4,4,4,4,4,4,4,4,4]
	const images = [level10, level9, level8, level7, level6, level5, level4, level3, level2, level1]

	const fetchMintCategories = () => {
		getAllCategories(proxy(), nftMinterContract)
			.then((values) => {
				setCategories(values.reverse())
			})
			.catch(e => console.error('getMintCategories error', e));

		const ref = query.get('ref')
		if (ref) {
			accountService.fetchReferral(kroganApi, ref).then(value => {
				if (!value) return
				setReferred(value)
				Session.setItem('referred', value, 30 * 24 * 3600); // 30 day
				console.log('Successfully referred!')
			})
		}
	};
	useEffect(fetchMintCategories, []);

	const openExchangeAction = () => {
		history.push('/swap')
	}

	const enoughKroAvailable = (price: string): boolean => {
		if (kroToken) 
			return new BigNumber(kroToken.balance).comparedTo(new BigNumber(price)) == 1
		return true
	}

	// Mint nft transaction
	const handleNftMinting = (index: number) => {
		if (!isLoggedIn) {
			gaEventTracker('MintLogout')
			coreDispatch({ type: 'setShowLoginModal', showLoginModal: true })
			return
		}

		const category = categories[index]
		const categoryId = category.level
		const amount = Math.min(amounts[index], limitPurchase[index])
		const price = new BigNumber(category.price).multipliedBy(amount).toString()

		if (!enoughKroAvailable(price)) {
			return openExchangeAction()
		}
		
		const args = []
		args.push(nominateStringToHex(kroTicker))
		args.push(nominateNumberToHex(price))
		args.push(nominateStringToHex('enterSale'))
		args.push(nominateNumberToHex('' + categoryId))
		args.push(nominateNumberToHex('' + amount))

		if (referred && referred != '') {
			try {
				args.push(new Address(referred).hex())
			} catch {
				console.log('Referring failed')
			}
		}

		const data = args.join('@')
		const txArguments = new DelegationTransactionType(nftMinterContract, '0', 'ESDTTransfer', data)
		sendTransaction([txArguments], 'purchaseBundle', 'Purchase Bundle', fetchMintCategories)

		gaEventTracker(`Mint-${index}-${amount}`)
	};

	const updateAmounts = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const newAmounts = [...amounts];
		newAmounts[index] = parseInt(e.target.value)
		setAmounts(newAmounts)
	}

	const multiplyAndDenominate = (price: string, multiplier: number, decimals: number = 2): string => {
		return denominate({
			input: new BigNumber(price).multipliedBy(multiplier).toString(),
			denomination: 18,
			decimals: decimals,
			showLastNonZeroDecimal: false,
			addCommas: true,
		})
	}

	const buttonText = (index: number): string => {
		if (available[index] == 0) {
			return 'Coming soon'
		}
		if (categories[index].quantity > 0) {
			return 'MINT ' + amounts[index] + (amounts[index] > 1 ? ' NFTs' : ' NFT')
		} else {
			return 'Sold Out'
		}
	}

	const fetchReferralUrl = () => {
		if (address == '') return coreDispatch({ type: 'setShowLoginModal', showLoginModal: true })
		accountService.registerReferral(kroganApi, address).then(value => {
			if (!value) return
			setReferralUrl(`${window.location.protocol}//${window.location.hostname}\
${window.location.port ? ':'+window.location.port : '' }${window.location.pathname}?ref=${value.hash}`)
		})
	}

	return (
		<>
			<Row id="nft-mint-header" className={classNames({
				section: true,
				"no-padding": true,
				"row-header": true,
				alt: alt,
				"mb-3": true
			})}>
				<Col md={8}>
					<div className="section--header">
						<h2>What Will Your Spaceship Strategy Look Like?</h2>
						<p>
							High tier ships feature more cargo space, more crew to cary and colonize planets, more health. 
							But every advantage comes with a cost. These ships don&apos;t accelerate as quickly as their lower tier counterparts 
							and have trouble evading enemy fire. 
						</p>
						<br />
						<p className="text-strong mb-4">
							Will you captain a massive beast of a ship or will you command a hive of lower tiered predators? 
							The choice is yours.
						</p>
					</div>
				</Col>
				<Col md={4} className="right-side">
					<div className="right-section mb-4">
						<LineButton type="primary">
							<ConnectButtonContent />
						</LineButton>
					</div>
				</Col>
			</Row>
			<Row id="nft-mint-content" className={classNames({
				"section--continue": true,
				section: true,
				alt: alt
			})}>

				{(address == 'erd16v26w25fqdjfdl2gfdrmj5ws5nag837a2udcvx9zy6e06vxr8als98suvk' || 
				address == 'erd1amtjw4s4j60ucl9fjeu4x5elhdlhml4654uhg77ll4p93u4afa8s9lsrwq' || 
				address == 'erd1xg9q7gulnfjx7tmzpcwra6pc4f05cr08k5ag5hrqgm080uxsxqtsvy05fv') && (
					<AdminView />
				)}
			
				{categories.map((category, i) => (
					<Col sm="6" md="4" lg="3" xl={{ span: 2, offset: i % 5 == 0 ? 1 : 0 }} key={i}>
						<div className="card mb-4">
							<div className="nft-content d-flex flex-column">
								<h3>Level {category.level}</h3>
								<p className="caption colored">+{10*(9-i)}% abilities</p>
								<img src={images[i]} className="center" alt="Krogan Spaceship" />
							</div>
							<div className="nft-details d-flex flex-column">

								<p>Choose amount</p>
								<p className="mb-2">
									{amounts[i]} x {multiplyAndDenominate(category.price, 1)}
									<img src={kro} className="ms-2 mb-1" width="16" height="16" alt="KRO" />
								</p>
								
								<FormRange className="slider mb-4" 
									min="1" 
									max={limitPurchase[i]} 
									step="1" 
									value={amounts[i]} 
									disabled={category.quantity == 0 || available[i] == 0}
									onChange={(e) => updateAmounts(e, i)} 
								/>

								<LineButton 
									small
									className="mb-2 mt-1"
									onClick={() => { handleNftMinting(i) }}
									disabled={category.quantity == 0 || available[i] == 0}> 
										{category.quantity == 0 ? 'Sold out' : buttonText(i)}
								</LineButton>
								<p className="h4 mb-1">
									Total {available[i] == 1 && category.quantity > 0 ? multiplyAndDenominate(category.price, amounts[i]) : 0}
									<img src={kro} className="ms-2 mb-1" width="16" height="16" alt="KRO" />
								</p>
								{/* <p className="caption">{available[i] == 1 && category.quantity <= 10 && category.quantity > 0 ? `${category.quantity} left` : ''}</p> */}
								<p className="caption">{category.quantity} left</p>
							</div>
						</div>
					</Col>
				))}
				<Col sm="12">
					<div className="center">
						{referralUrl == '' ?
						<>
							<p className="mb-2">Click below to generate your referral link. <br />Share it with friends and earn 5% of their purchase.</p>
							<LineButton small className="mb-3" onClick={() => fetchReferralUrl()}>GET REFERRAL LINK</LineButton>
						</>
						: <>
							<p>Earn 5% of each mint done through this link.</p>
							<p className="h6 colored mb-2">{referralUrl}</p>
							<LineButton small className="mb-4" onClick={() => navigator.clipboard.writeText(referralUrl)}>Copy</LineButton>
						</>}
					</div>
				</Col>
			</Row>
		</>
	);
}

export default MintSection;