import React from 'react'
import classNames from "classnames";
import { Col, Row } from 'react-bootstrap';
import AliceCarousel from 'react-alice-carousel'

import todo from 'images/roadmap/todo.png'
import done from 'images/roadmap/done.png'

const RoadmapSection = ({
    alt,
}: {
    alt: boolean;
}): React.ReactElement => {

	const responsive = {
		0: { items: 1 },
		568: { items: 3 },
		1024: { items: 5 },
	};

	// .timeline + .inprogress / .complete

	const items = [
		<div className="period" key="1">
			<div className="period__content h--1">
				<h5>Q1 2023</h5>
				<h3>Ignite!</h3>
				<div className="timeline inprogress"></div>
				<div className="timeline__period">
					<div className="item">
						<img src={done} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Krogan NFT Dynamic attributes</span>
					</div>
					<div className="item">
						<img src={done} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">KRO Tokenomics V2.0</span>
					</div>
					<div className="item">
						<img src={done} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Extreme Staking pool end</span>
					</div>
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Early testers access - Devnet</span>
					</div>
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Beta release - Mainnet</span>
					</div>
				</div>
			</div>
		</div>,
		<div className="period" key="2">
			<div className="period__content h--2">
				<h5>Q2 2023</h5>
				<h3>Showcase</h3>
				<div className="timeline"></div>
				<div className="timeline__period">
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Game bug fixing & feature requests</span>
					</div>
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Krogan NFT visual upgrade to V2</span>
					</div>
					<div className="item">
						<img src={done} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Marketplace rebranding</span>
					</div>
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Game & Players stats dashboards</span>
					</div>
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Game release V1.0</span>
					</div>
				</div>
			</div>
		</div>,
		<div className="period" key="3">
			<div className="period__content h--3">
				<h5>Q3 2023</h5>
				<h3>Ecosystem growth</h3>
				<div className="timeline"></div>
				<div className="timeline__period">
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Marketplace new features</span>
					</div>
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Marketplace game integration</span>
					</div>
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Krogan Ecosystem tools</span>
					</div>
				</div>
			</div>
		</div>,
		<div className="period" key="4">
			<div className="period__content h--4">
				<h5>Q4 2023</h5>
				<h3>Expand</h3>
				<div className="timeline"></div>
				<div className="timeline__period">
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Krogan Captain NFT Collection</span>
					</div>
					<div className="item">
						<img src={todo} alt="Milestone Progress" className="item__icon" />
						<span className="ps-1">Game Expansions</span>
					</div>
				</div>
			</div>
		</div>
	];
	
	const renderSlideInfo = ({ item, itemsCount }: { item: number, itemsCount: number }) => {
		return `${item}\\${itemsCount}`;
	};
	
	const renderPrevButton = ({ isDisabled }: { isDisabled: boolean }) => {
		return <span style={{ opacity: isDisabled ? '0.5' : 1 }} className="round">&#8249;</span>;
	};
	
	const renderNextButton = ({ isDisabled }: { isDisabled: boolean }) => {
		return <span style={{ opacity: isDisabled ? '0.5' : 1 }} className="round">&#8250;</span>;
	};

	return (
		<Row id="roadmap" className={classNames({
			section: true,
			alt: alt
		})}>
			<Col>
				<h2 className="mb-4">Roadmap</h2>
				{/* Revamp using https://maxmarinich.github.io/react-alice-carousel/#custom-components */}
				<AliceCarousel 
					mouseTracking
					responsive={responsive}
					renderSlideInfo={renderSlideInfo}
					controlsStrategy="responsive"
					renderPrevButton={renderPrevButton}
					renderNextButton={renderNextButton}
					items={items} 
				/>
			</Col>
		</Row>
	);
}

export default RoadmapSection;