import BigNumber from 'bignumber.js';
import React, { useEffect, useRef, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { 
	DelegationTransactionType, denominate, nominateNumberToHex, 
	nominateStringToHex, useAccount, useCoreContext, useCoreDispatch, useTransactions 
} from 'core';
import { egldWrapperContract, exchangeTokens } from 'config';
import { parseAmount } from '@multiversx/sdk-dapp/utils';
import { Address } from '@multiversx/sdk-core/out';
import { exchangePairs } from 'services/blockchain';
import { ExchangePair, ExchangeToken } from 'utils/types';
import { contractViews } from 'contracts/ContractViews';
import { LineButton } from '../uielements/LineButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEvents';

const Exchange = (): React.ReactElement => {

	const dispatch = useCoreDispatch()

	const { proxy, loadAccount, refreshTokenList } = useAccount()
	const { address } = useCoreContext()
	const { sendTransaction } = useTransactions()
	const gaEventTracker = useAnalyticsEventTracker('Swap')

	const [tokenIn, setTokenIn] = useState(exchangeTokens.find(token => token.identifier == 'EGLD') as ExchangeToken)
	const [tokenOut, setTokenOut] = useState(exchangeTokens.find(token => token.identifier == 'KRO-df97ec') as ExchangeToken)
	
	const [amountIn, setAmountIn] = useState('')
	const [intermediateOut, setIntermediateOut] = useState('')
	const [amountOut, setAmountOut] = useState('')

	const [pairs, setPairs] = useState([] as ExchangePair[])
	const [route, setRoute] = useState([] as ExchangePair[])

	const [loading, setLoading] = useState(false)
	const timeoutRef = useRef<NodeJS.Timeout>()

	const getSwapSimulation = () => {
		exchangePairs().then(value => {
			setPairs(value.data.pairs) // TODO: add here the KRO-ANT pair also
		})
	}
	useEffect(getSwapSimulation, [])

	const wrapConversion = (identifier: string) => {
		return identifier == 'EGLD' ? 'WEGLD-bd4d79' : identifier
	}

	const findRoute = () => {
		if (pairs.length == 0) return
		if (amountIn != '') setLoading(true)

		let finalTokenIn = wrapConversion(tokenIn.identifier)
		let finalTokenOut = wrapConversion(tokenOut.identifier)
		
		let routes = []
		
		// EG: EGLD - USDC - KRO -- Adding EGLD - USDC
		if (tokenIn.identifier == 'EGLD' && tokenOut.intermediate) {
			// console.log(`Looking for ${finalTokenIn} - ${tokenOut.intermediate}`)
			const found = pairs.find(pair => 
				pair.firstToken.identifier == finalTokenIn && pair.secondToken.identifier == tokenOut.intermediate
			)
			// console.log(found)
			if (found) {
				found.swapFrom = tokenIn
				routes.push(found)
			}
		}

		// EG: KRO - USDC - EGLD -- Adding KRO - USDC
		if (tokenOut.identifier == 'EGLD' && tokenIn.intermediate) {
			// console.log(`Looking for ${finalTokenOut} - ${tokenIn.intermediate}`)
			const found = pairs.find(pair => 
				pair.firstToken.identifier == finalTokenIn && pair.secondToken.identifier == tokenIn.intermediate
			)
			// console.log(found)
			if (found) {
				found.swapFrom = tokenIn
				routes.push(found)
			}
		}


		const intermediate = tokenIn.intermediate ?? tokenOut.intermediate
		const filtered = pairs.filter(pair => 
			pair.firstToken.identifier == finalTokenOut || pair.secondToken.identifier == finalTokenOut
		)

		// EG: KRO/EGLD - USDC - EGLD/KRO -- Adding USDC - EGLD/KRO
		if (intermediate && intermediate != finalTokenOut && intermediate != finalTokenIn) {
			const found = filtered.find(pair => 
				pair.firstToken.identifier == intermediate || pair.secondToken.identifier == intermediate
			)
			// console.log(found)
			if (found) {
				found.swapFrom = exchangeTokens.find(token => token.identifier == intermediate)!
				routes.push(found)
			}
		} else {
			// EG: KRO - USDC OR EGLD - USDC -- Direct cases
			const found = filtered.find(pair => 
				pair.firstToken.identifier == finalTokenIn || pair.secondToken.identifier == finalTokenIn
			)
			// console.log(found)
			if (found) {
				found.swapFrom = tokenIn
				routes.push(found)
			}
		}

		setRoute(routes)
	}
	useEffect(findRoute, [pairs, tokenIn, tokenOut])

	const calculateMinimumAmount = (amount: BigNumber, pair: ExchangePair) => {
		const result = amount
			.minus(amount.multipliedBy(new BigNumber(pair.totalFeePercent).plus(0.0001)).toFixed(0))
			.toString()
		return result
	}

	const findAmountOut = async () => {
		// console.log(route)
		if (route.length == 0 || amountIn == '') return

		// Find first pair
		const firstPair = route.first()
		const firstAmount = await contractViews.getAmountOut(
			proxy(), 
			firstPair.address, 
			wrapConversion(firstPair.swapFrom?.identifier ?? ''), 
			parseAmount(amountIn, firstPair.swapFrom?.decimals ?? 18)
		)
		const firstPairAmount = calculateMinimumAmount(firstAmount, firstPair)

		// If routing is needed, look for second pair
		
		if (route.length == 1) {
			setLoading(false)
			return setAmountOut(firstPairAmount)
		}
		setIntermediateOut(firstPairAmount)

		const secondPair = route.last()
		const secondAmount = await contractViews.getAmountOut(
			proxy(), 
			secondPair.address, 
			wrapConversion(secondPair.swapFrom?.identifier ?? ''), 
			firstPairAmount
		)
		setAmountOut(calculateMinimumAmount(secondAmount, secondPair))
		setLoading(false)
	}

	const amountInUpdated = () => {
		if (amountIn == '') return
		setLoading(true)

		if (timeoutRef.current) 
			clearTimeout(timeoutRef.current)

		timeoutRef.current = setTimeout(() => { findAmountOut() }, 500)
	}
	useEffect(amountInUpdated, [route, amountIn])

	const switchSwap = () => {
		setAmountIn(denominate({
			input: amountOut, 
			denomination: tokenOut.decimals, 
			decimals: 3,
			addCommas: false
		}))

		const alt = tokenOut
		setTokenOut(tokenIn)
		setTokenIn(alt)
	}

	const pairExchangeRate = (pair: ExchangePair) => {
		const firstToken = denominate({ input: pair.info.reserves0, denomination: pair.firstToken.decimals, decimals: 6, addCommas: false })
		const secondToken = denominate({ input: pair.info.reserves1, denomination: pair.secondToken.decimals, decimals: 6, addCommas: false })
		return parseFloat(secondToken) / parseFloat(firstToken)
	}

	const prettifyIdentifier = (identifier: string) => {
		return identifier.split('-').first()
	}

	const exchange = () => {
		if (address == '') return dispatch({ type: 'setShowLoginModal', showLoginModal: true })

		gaEventTracker('Swap Init', tokenOut.identifier)

		const txs = []
		if (tokenIn.identifier == 'EGLD') {
			txs.push(new DelegationTransactionType(egldWrapperContract, amountIn, 'wrapEgld', undefined, 0.5))
		}

		const args = []
		args.push(nominateStringToHex(wrapConversion(tokenIn.identifier)))
		args.push(nominateNumberToHex(parseAmount(amountIn, tokenIn.decimals)))
		args.push(nominateStringToHex('multiPairSwap'))

		const amounts = route.length == 1 ? [amountOut] : [intermediateOut, amountOut]
		
		if (tokenOut.intermediate || tokenIn.intermediate) {	
			const pair1 = route[0]
			args.push(new Address(pair1.address).hex())
			args.push(nominateStringToHex('swapTokensFixedInput'))
			args.push(nominateStringToHex(tokenOut.intermediate ?? tokenIn.intermediate ?? ''))
			args.push(nominateNumberToHex(amounts[0]))

			const pair2 = route[1]
			args.push(new Address(pair2.address).hex())
			args.push(nominateStringToHex('swapTokensFixedInput'))
			args.push(nominateStringToHex(wrapConversion(tokenOut.identifier)))
			args.push(nominateNumberToHex(amounts[1]))
		} else {
			const pair1 = route[0]
			args.push(new Address(pair1.address).hex())
			args.push(nominateStringToHex('swapTokensFixedInput'))
			args.push(nominateStringToHex(wrapConversion(tokenOut.identifier)))
			args.push(nominateNumberToHex(amounts[0]))
		}

		const data = args.join('@')
		txs.push(new DelegationTransactionType('erd1qqqqqqqqqqqqqpgqq66xk9gfr4esuhem3jru86wg5hvp33a62jps2fy57p', '0', 'ESDTTransfer', data, 3))

		if (tokenOut.identifier == 'EGLD') {
			const args = []
			args.push(nominateStringToHex('WEGLD-bd4d79'))
			args.push(nominateNumberToHex(amountOut))
			args.push(nominateStringToHex('unwrapEgld'))

			const data = args.join('@')
			txs.push(new DelegationTransactionType(egldWrapperContract, '0', 'ESDTTransfer', data, 0.2))
		}

		setAmountIn('')
		setIntermediateOut('')
		setAmountOut('')
		sendTransaction(txs, 'swap', 'Swap Tokens', () => {
			gaEventTracker('Swap Complete', tokenOut.identifier)
		})
	}

	// ESDTTransfer@
	// 5745474c442d626434643739@ // WEGLD
	// 0de0b6b3a7640000@ // amount
	// 6d756c74695061697253776170@ // multiPairSwap
	// 00000000000000000500ce7eab736978ce9492ebbf8206f252eacb333cfa5483@ // erd1qqqqqqqqqqqqqpgqeel2kumf0r8ffyhth7pqdujjat9nx0862jpsg2pqaq
	// 73776170546f6b656e734669786564496e707574@ // swapTokensFixedInput
	// 555344432d633736663166@ // USDC-c76f1f
	// 0293b8fd@ // amount
	// 000000000000000005007a6f1a96f8adeff35e35b252838d9873727a13105483@ // erd1qqqqqqqqqqqqqpgq0fh349hc4hhlxh34kffg8rvcwde85ycs2jpsk6h269
	// 73776170546f6b656e734669786564496e707574@ // swapTokensFixedInput
	// 4b524f2d646639376563@ // KRO-df97ec
	// 049bc75ea8b3687f29a4 // amount

	return (
		<div className='block bordered p-3'>
			<img src="/images/KRO-878dce.gif" width={48} alt="Kro Token - Play to Earn" className="mb-3" />
			<h4>KRO Swaps in ONE click</h4>
			<div className="d-flex input-container center primary mt-4">
				<FormControl
					type="text"
					name="amount"
					value={amountIn}
					onChange={(e: any) => setAmountIn(e.target.value)}
					placeholder={`${prettifyIdentifier(tokenIn.identifier)} Amount`}
				/>
				<Form.Select value={tokenIn.identifier} onChange={(e: any) => {
					const token = exchangeTokens.find(token => token.identifier == e.target.value)!
					if (token.identifier == tokenOut.identifier) {
						setTokenOut(tokenIn)
					}
					setTokenIn(token)
				}}>
					{exchangeTokens.map((token, i) => (
						<option key={i} value={token.identifier}>{prettifyIdentifier(token.identifier)}</option>
					))}
				</Form.Select>
			</div>
			
			<button onClick={switchSwap}><FontAwesomeIcon icon={faArrowRightArrowLeft} className='colored fa-rotate-90 mt-2' /></button>
			
			<div className="d-flex input-container center primary mt-2">
				<FormControl
					type="text"
					name="amount"
					disabled={true}
					value={loading ? 'Loading..' : route.length > 0 && amountOut != '' ? denominate({
						input: amountOut, 
						denomination: tokenOut.decimals, 
						decimals: 3
					}) : ''}
					placeholder={`${prettifyIdentifier(tokenOut.identifier)} Received`}
				/>
				<Form.Select value={tokenOut.identifier} onChange={(e: any) => {
					const token = exchangeTokens.find(token => token.identifier == e.target.value)!
					if (token.identifier == tokenIn.identifier) {
						setTokenIn(tokenOut)
					}
					setTokenOut(token)
				}}>
					{exchangeTokens.map((token, i) => (
						<option key={i} value={token.identifier}>{prettifyIdentifier(token.identifier)}</option>
					))}
				</Form.Select>
			</div>
			<LineButton onClick={exchange} className="mt-3 w-50">Swap</LineButton>

			<div>
				<p className='mt-3 text-action'>Route</p>
				{tokenIn.identifier == 'EGLD' && <p className='footnote'>Wrap EGLD</p>}
				{route.map((pair, i) => (
					<p className='footnote' key={i}>1 {prettifyIdentifier(pair.firstToken.identifier)} = {pairExchangeRate(pair).toFixed(5)} {prettifyIdentifier(pair.secondToken.identifier)}</p>
				))}
				{tokenOut.identifier == 'EGLD' && <p className='footnote'>Unwrap EGLD</p>}
			</div>
		</div>
	);
}

export default Exchange;